var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yqg-header", attrs: { id: "top" } }, [
    _c(
      "div",
      { staticClass: "nav-list sm-hide" },
      [
        _c("a", { staticClass: "nav", attrs: { href: "/#top" } }, [
          _vm._v("主页"),
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "nav", attrs: { href: "/#about" } }, [
          _vm._v("关于"),
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "nav", attrs: { href: "/#services" } }, [
          _vm._v("服务"),
        ]),
        _vm._v(" "),
        _c("img", {
          attrs: { src: require("./img/Lingyue_logo_en_white.png") },
        }),
        _vm._v(" "),
        _c("a", { staticClass: "nav", attrs: { href: "/#portfolio" } }, [
          _vm._v("应用场景"),
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "nav", on: { click: _vm.showContractUs } }, [
          _vm._v("联系我们"),
        ]),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "nav",
            attrs: { to: { name: "paymentInformationList" } },
          },
          [_vm._v("\n            收费资讯\n        ")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "sm-show" }, [
      _c("div", { staticClass: "nav-menu" }, [
        _c("img", {
          attrs: { src: require("./img/Lingyue_logo_en_white.png") },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "icon-menu" }, [
          _c(
            "div",
            { staticClass: "menu" },
            [
              _c("a", { staticClass: "nav", attrs: { href: "/#top" } }, [
                _vm._v("主页"),
              ]),
              _vm._v(" "),
              _c("a", { staticClass: "nav", attrs: { href: "/#about" } }, [
                _vm._v("关于"),
              ]),
              _vm._v(" "),
              _c("a", { staticClass: "nav", attrs: { href: "/#services" } }, [
                _vm._v("服务"),
              ]),
              _vm._v(" "),
              _c("a", { staticClass: "nav", attrs: { href: "/#portfolio" } }, [
                _vm._v("应用场景"),
              ]),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "nav", on: { click: _vm.showContractUs } },
                [_vm._v("联系我们")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "nav",
                  attrs: { to: { name: "paymentInformationList" } },
                },
                [
                  _vm._v(
                    "\n                        收费资讯\n                    "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }