<!-- @author hongweihu -->
<!-- @email hongweihu@fintopia.tech -->
<!-- @date 2022-06-08 13:31:35.065 -->
<!-- @desc generated by yqg-cli@5.3.0 -->

<template>
    <div
        id="top"
        class="yqg-header"
    >
        <div class="nav-list sm-hide">
            <a
                class="nav"
                href="/#top"
            >主页</a>
            <a
                class="nav"
                href="/#about"
            >关于</a>
            <a
                class="nav"
                href="/#services"
            >服务</a>
            <img src="./img/Lingyue_logo_en_white.png">
            <a
                class="nav"
                href="/#portfolio"
            >应用场景</a>
            <!-- <a class="nav" href="/#team">团队介绍</a> -->
            <a
                class="nav"
                @click="showContractUs"
            >联系我们</a>
            <router-link
                class="nav"
                :to="{name: 'paymentInformationList'}"
            >
                收费资讯
            </router-link>
        </div>
        <div class="sm-show">
            <div class="nav-menu">
                <img src="./img/Lingyue_logo_en_white.png">
                <div class="icon-menu">
                    <div class="menu">
                        <a
                            class="nav"
                            href="/#top"
                        >主页</a>
                        <a
                            class="nav"
                            href="/#about"
                        >关于</a>
                        <a
                            class="nav"
                            href="/#services"
                        >服务</a>
                        <a
                            class="nav"
                            href="/#portfolio"
                        >应用场景</a>
                        <!-- <a class="nav" href="/#team">团队介绍</a> -->
                        <a
                            class="nav"
                            @click="showContractUs"
                        >联系我们</a>
                        <router-link
                            class="nav"
                            :to="{name: 'paymentInformationList'}"
                        >
                            收费资讯
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">

export default {
    name: 'YqgHeader',

    methods: {
        showContractUs() {
            this.$emit('show-contract-us');
        }
    }
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $ultra-book-min: 760px;

    .yqg-header {
        a {
            text-decoration: none;
        }

        .nav-list {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba($color: #000, $alpha: 80%);
            background-repeat: no-repeat;
            background-size: cover;

            img {
                width: 295px;
            }

            .nav {
                padding: 55px 14px 45px;
                border-top: 3px solid transparent;
                border-bottom-style: none;
                color: #fff;
                font-size: 13px;
                font-weight: 700;
                letter-spacing: 1px;
                cursor: pointer;

                &:hover {
                    border-top: 3px solid #ef5822;
                }
            }
        }

        @media screen and (max-width: $ultra-book-min) {
            .sm-hide {
                display: none;
            }

            .sm-show {
                display: block;
            }

            .nav-menu {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 18px 10px 0;
                background-color: rgba($color: #000, $alpha: 80%);

                .icon-menu {
                    position: static;
                    display: inline-block;
                    box-sizing: content-box;
                    width: 20px;
                    height: 2px;
                    padding: 2px 0;
                    border-top: 2px solid #fff;
                    border-bottom: 2px solid #fff;
                    background-color: #fff;
                    background-clip: content-box;

                    .menu {
                        position: absolute;
                        left: 0;
                        top: 74px;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        background-color: rgba($color: #000, $alpha: 80%);
                        height: 0;
                        overflow: hidden;
                        transition: height 0.5s ease;
                        z-index: 999;

                        .nav {
                            padding: 20px;
                            font-size: 14px;
                            color: #adadad;
                            font-weight: 600;
                        }
                    }

                    &:hover {
                        .menu {
                            height: 436.73px;
                        }
                    }
                }

                img {
                    width: 180px;
                }
            }
        }
    }

</style>
