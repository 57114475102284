<!-- @Author: hongweihu -->
<!-- @Date: 2022-06-09 16:19:45.281 -->
<!-- @Last Modified by: hongweihu -->
<!-- @Last Modified time: 2023-10-23 13:00:27 -->

<template>
    <div
        class="contact-us"
        :class="{show}"
    >
        <div class="btn-group">
            <a
                class="remove-button"
                href="javascript:void(0)"
                @click="changeShow(false)"
            >
                <img src="./img/close.svg">
            </a>
        </div>
        <div class="title">
            公司地址
        </div>
        <p class="comp sm-text">
            {{ companyInfo.companyName }}
        </p>
        <p class="detail sm-text">
            {{ companyInfo.address }}<br>
            朝阳，北京，中国<br>
            联系电话：{{ companyInfo.mobile }}
        </p>
    </div>
</template>

<script type="text/babel">
    import {mapGetters} from 'vuex';

    import {G_TYPES} from 'moneycan/common/store/company-info';

    export default {
        name: 'ContactUs',

        data() {
            return {
                show: false
            };
        },

        computed: {
            ...mapGetters({
                companyInfo: G_TYPES.GET_COMPANY_INFO
            })
        },

        methods: {
            changeShow(show) {
                this.show = show;
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $ultra-book-min: 760px;

    .contact-us {
        position: fixed;
        right: 0;
        top: 0;
        padding: 20px;
        height: 100%;
        width: 320px;
        box-sizing: border-box;
        background-color: rgba($color: #000, $alpha: 80%);
        transform: translateX(320px);
        transition: transform 0.5s ease;
        z-index: 9999;

        &.show {
            transform: translateX(0);
        }

        .btn-group {
            margin-bottom: 20px;
            text-align: right;

            .remove-button {
                display: inline-block;
                width: 30px;
                height: 30px;
                border: 2px solid #7d7d7d;
                border-radius: 17px;
                text-align: center;
                box-sizing: content-box;

                img {
                    width: 11px;
                    height: 11px;
                    vertical-align: -4px;
                    display: inline-block;
                }
            }
        }

        .title {
            padding-bottom: 15px;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            border-bottom: 1px solid #474747;
        }

        .sm-text {
            margin-bottom: 5px;
            padding-right: 0;
            padding-left: 0;
            font-family: Raleway, sans-serif;
            color: #919191;
            font-size: 13px;
            line-height: 26px;
            font-weight: 400;
            background-color: transparent !important;
        }

        .comp {
            margin: 30px 0;
        }

        @media screen and (max-width: $ultra-book-min) {
            width: 100vw;
            transform: translateX(100vw);

            &.show {
                transform: translateX(0);
            }

            .btn-group {
                .remove-button {
                    img {
                        vertical-align: -7px;
                    }
                }
            }
        }
    }

</style>
