var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact-us", class: { show: _vm.show } }, [
    _c("div", { staticClass: "btn-group" }, [
      _c(
        "a",
        {
          staticClass: "remove-button",
          attrs: { href: "javascript:void(0)" },
          on: {
            click: function ($event) {
              return _vm.changeShow(false)
            },
          },
        },
        [_c("img", { attrs: { src: require("./img/close.svg") } })]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "title" }, [_vm._v("\n        公司地址\n    ")]),
    _vm._v(" "),
    _c("p", { staticClass: "comp sm-text" }, [
      _vm._v("\n        " + _vm._s(_vm.companyInfo.companyName) + "\n    "),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "detail sm-text" }, [
      _vm._v("\n        " + _vm._s(_vm.companyInfo.address)),
      _c("br"),
      _vm._v("\n        朝阳，北京，中国"),
      _c("br"),
      _vm._v(
        "\n        联系电话：" + _vm._s(_vm.companyInfo.mobile) + "\n    "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }