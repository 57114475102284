<!-- @Author: hongweihu -->
<!-- @Date: 2022-06-08 13:31:57.342 -->
<!-- @Last Modified by: hongweihu -->
<!-- @Last Modified time: 2023-10-23 13:00:29 -->

<template>
    <div class="yqg-footer">
        <div class="banner">
            <div class="big-text tail">
                马上申请注册，助力业务增长！
            </div>
            <div class="div-center up">
                <a
                    class="button button-hero"
                    href="javascript:void(0)"
                    @click="showContractUs"
                >联系我们</a>
            </div>
        </div>
        <div class="copyright">
            <div class="container">
                <span>© 2015-2017 {{ companyInfo.companyName }}</span>
                <a
                    href="https://beian.miit.gov.cn/#/Integrated/index"
                    target="_blank"
                    class="right"
                >
                    {{ companyInfo.copyright }}
                </a>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
    import {mapGetters} from 'vuex';

    import {G_TYPES} from 'moneycan/common/store/company-info';

    export default {
        name: 'YqgFooter',

        computed: {
            ...mapGetters({
                companyInfo: G_TYPES.GET_COMPANY_INFO
            })
        },

        methods: {
            showContractUs() {
                this.$emit('show-contract-us');
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $ultra-book-min: 760px;

    .yqg-footer {
        .banner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 150px;
            padding-bottom: 150px;
            background-image: linear-gradient(180deg, rgba(0, 0, 0, 51%), transparent), url(./img/bg.jpg);
            background-size: auto, cover;
            background-repeat: repeat, no-repeat;
            background-attachment: scroll, fixed;

            .tail {
                margin-bottom: 53px;
                color: #fff;
                font-size: 40px;
                font-weight: 600;

                @media screen and (max-width: $ultra-book-min) {
                    font-size: 20px;
                }
            }

            .button {
                display: inline-block;
                padding: 10px 20px;
                border: 1px solid #dbdbdb;
                border-radius: 5px;
                border-color: #fff;
                background-color: transparent;
                opacity: 75%;
                transition: opacity 500ms ease;
                color: #fff;
                font-size: 13px;
                line-height: 32px;
                font-weight: 600;
                text-decoration: none;
            }
        }

        .copyright {
            padding: 30px 0;
            color: #919191;
            font-size: 15px;
            background-color: rgba(0, 0, 0, 80%);

            .container {
                display: flex;
                justify-content: space-between;

                a {
                    color: #919191;
                    text-decoration: none;
                }

                @media screen and (max-width: $ultra-book-min) {
                    flex-direction: column;
                    align-items: center;

                    a {
                        margin-top: 20px;
                    }
                }
            }
        }
    }

</style>
