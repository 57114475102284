var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yqg-footer" }, [
    _c("div", { staticClass: "banner" }, [
      _c("div", { staticClass: "big-text tail" }, [
        _vm._v("\n            马上申请注册，助力业务增长！\n        "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "div-center up" }, [
        _c(
          "a",
          {
            staticClass: "button button-hero",
            attrs: { href: "javascript:void(0)" },
            on: { click: _vm.showContractUs },
          },
          [_vm._v("联系我们")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "copyright" }, [
      _c("div", { staticClass: "container" }, [
        _c("span", [
          _vm._v("© 2015-2017 " + _vm._s(_vm.companyInfo.companyName)),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "right",
            attrs: {
              href: "https://beian.miit.gov.cn/#/Integrated/index",
              target: "_blank",
            },
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm.companyInfo.copyright) +
                "\n            "
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }